import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default () => (
  <Layout>
    <SEO title="Viewports" />
    <div className="edge">
        <h1>Utilities</h1>
        <p className="text-preset-large-intro">Page coming soon</p>
    </div>
  </Layout>
)
